import axios from 'axios'
import { getConfig } from '@/common/config'
import * as oidcHelper from '@/common/oidc/oidcHelper'

const instance = axios.create()

instance.interceptors.request.use(
  (config) => {
    config.baseURL = getConfig().apiBaseUrl
    let authMode = getConfig().authMode

    if (authMode === 'oidc') {
      return oidcHelper
        .getAccessToken()
        .then((at) => {
          console.log('start access token set in axios.', at)

          config.headers.Authorization = 'Bearer ' + at

          console.log('access token set done.')

          return Promise.resolve(config)
        })
        .catch((error) => {
          console.error('error in get access token in axios.', error)
          return Promise.reject(error)
        })
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { instance as axiosInstance }
