import { defineStore } from 'pinia'

export const useUiStore = defineStore({
  id: 'uiStore',
  persist: {
    key: import.meta.env.VITE_LOCAL_STORAGE_PREFIX + 'uiStore',
    storage: localStorage
  },
  state: () => ({
    menuCollapsed: false,
    openedMenus: [],
    globalLoading: false,
    theme: 'default',
    tableColumnSelection: {},
    jsonQLData: {}
  }),
  getters: {
    getterMenuCollapsed: (state) => {
      return state.menuCollapsed
    },
    getterOpenedMenus: (state) => {
      return state.openedMenus
    },
    getterGlobalLoading: (state) => {
      return state.globalLoading
    },
    getterTheme: (state) => {
      return state.theme
    },
    getterJsonQLData: (state) => {
      return state.jsonQLData
    }
  },
  actions: {
    toggleMenu() {
      this.menuCollapsed = !this.menuCollapsed
      return Promise.resolve()
    },
    saveOpenedMenus(payload) {
      this.openedMenus = payload
      return Promise.resolve()
    },
    setGlobalLoading(payload) {
      this.globalLoading = payload
      return Promise.resolve()
    },
    setTheme(payload) {
      this.theme = payload
      return Promise.resolve()
    },
    setJsonQLData(payload) {
      this.jsonQLData = payload
      return Promise.resolve()
    }
  }
})
