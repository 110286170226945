import { defineStore } from 'pinia'
import * as securityService from '@/api/securityService'

export const useSecurityStore = defineStore({
  id: 'securityStore',
  persist: {
    key: import.meta.env.VITE_LOCAL_STORAGE_PREFIX + 'securityStore',
    storage: localStorage
  },
  state: () => ({
    menus: null,
    permissions: null
  }),
  getters: {
    getterMenus: (state) => {
      return state.menus
    },
    getterPermissions: (state) => {
      return state.permissions
    }
  },
  actions: {
    generateMenus() {
      return securityService
        .getMenus()
        .then((res) => {
          this.menus = res
          console.log('got menus', res)
        })
        .catch((error) => {
          console.error('generateMenus error', error.response)
          return Promise.reject(error)
        })
    },
    generatePermissions() {
      return securityService
        .getPermissions()
        .then((res) => {
          this.permissions = res
          console.log('got permissions', res)
        })
        .catch((error) => {
          console.error('generatePermissions error', error.response)
          return Promise.reject(error)
        })
    },
    clear() {
      this.menus = null
      this.permissions = null
      return Promise.resolve()
    }
  }
})
