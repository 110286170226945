// lazy loading
let routes = [
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/oidc-callback',
        component: () => import('@/views/OidcCallback.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.loading'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/oidc-silent-callback',
        component: () => import('@/views/OidcSilentCallback.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.loading'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/403',
        component: () => import('@/views/403.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.access_denied'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/404',
        component: () => import('@/views/404.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.not_found'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/error',
        component: () => import('@/views/ErrorPage.vue'),
        name: 'error',
        hidden: true,
        meta: {
          title: 'router.error'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: '',
    iconCls: 'gt-icon-home',
    leaf: true,
    children: [
      {
        path: '/',
        component: () => import('@/views/home/HomePage.vue'),
        name: 'home',
        meta: {
          title: 'router.home'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: 'application-main',
    iconCls: 'gt-icon-cog',
    meta: {
      title: 'router.application'
    },
    children: [
      {
        path: '/app',
        component: () => import('@/views/app/AppPage.vue'),
        name: 'app',
        meta: {
          protected: true,
          title: 'router.app_main'
        }
      },
      {
        path: '/module',
        component: () => import('@/views/module/ModuleMain.vue'),
        name: 'module',
        meta: {
          protected: true,
          title: 'router.module_main'
        }
      },
      {
        path: '/role',
        component: () => import('@/views/role/RoleMain.vue'),
        name: 'role',
        meta: {
          protected: true,
          title: 'router.role_main'
        }
      },
      {
        path: '/resource',
        component: () => import('@/views/resource/ResourceMain.vue'),
        name: 'resource',
        meta: {
          protected: true,
          title: 'router.resource_main'
        }
      },
      {
        path: '/operation',
        component: () => import('@/views/operation/OperationMain.vue'),
        name: 'operation',
        meta: {
          protected: true,
          title: 'router.operation_main'
        }
      },
      {
        path: '/permission',
        component: () => import('@/views/permission/PermissionMain.vue'),
        name: 'permission',
        meta: {
          protected: true,
          title: 'router.permission_main'
        }
      },
      {
        path: '/menu-group',
        component: () => import('@/views/menuGroup/MenuGroupMain.vue'),
        name: 'menu-group',
        meta: {
          protected: true,
          title: 'router.menu_group_main'
        }
      },
      {
        path: '/menu',
        component: () => import('@/views/menu/MenuMain.vue'),
        name: 'menu',
        meta: {
          protected: true,
          title: 'router.menu_main'
        }
      },
      {
        path: '/asset',
        component: () => import('@/views/asset/AssetMain.vue'),
        name: 'asset',
        meta: {
          protected: true,
          title: 'router.asset_main'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: 'organization',
    iconCls: 'gt-icon-cog',
    meta: {
      title: 'router.organization'
    },
    children: [
      {
        path: '/user',
        component: () => import('@/views/user/UserMain.vue'),
        name: 'user',
        meta: {
          protected: true,
          title: 'router.user_main'
        }
      },
      {
        path: '/group',
        component: () => import('@/views/group/GroupMain.vue'),
        name: 'group',
        meta: {
          protected: true,
          title: 'router.group_main'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: 'oidc-main',
    iconCls: 'gt-icon-cog',
    meta: {
      title: 'router.oidc'
    },
    children: [
      {
        path: '/client',
        component: () => import('@/views/client/ClientMain.vue'),
        name: 'client',
        meta: {
          protected: true,
          title: 'router.client_main'
        }
      },
      {
        path: '/api-resource',
        component: () => import('@/views/apiResource/ApiResourceMain.vue'),
        name: 'api-resource',
        meta: {
          protected: true,
          title: 'router.api_resource_main'
        }
      },
      {
        path: '/identity-resource',
        component: () => import('@/views/identityResource/IdentityResourceMain.vue'),
        name: 'identity-resource',
        meta: {
          protected: true,
          title: 'router.identity_resource_main'
        }
      },
      {
        path: '/api-scope',
        component: () => import('@/views/apiScope/ApiScopeMain.vue'),
        name: 'api-scope',
        meta: {
          protected: true,
          title: 'router.api_scope_main'
        }
      },
      {
        path: '/refresh-token',
        component: () => import('@/views/refreshToken/RefreshTokenMain.vue'),
        name: 'refresh-token',
        meta: {
          protected: true,
          title: 'router.refresh_token_main'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: 'system-main',
    iconCls: 'gt-icon-cog',
    meta: {
      title: 'router.system'
    },
    children: [
      {
        path: '/system-config',
        component: () => import('@/views/systemConfig/SystemConfigMain.vue'),
        name: 'system-config',
        meta: {
          protected: true,
          title: 'router.system_config_main'
        }
      },
      {
        path: '/system-log',
        component: () => import('@/views/systemLog/SystemLogMain.vue'),
        name: 'system-log',
        meta: {
          protected: true,
          title: 'router.system_log_main'
        }
      },
      {
        path: '/email-template',
        component: () => import('@/views/emailTemplate/EmailTemplateMain.vue'),
        name: 'email-template',
        meta: {
          protected: false,
          title: 'router.email_template_main'
        }
      },
      {
        path: '/short-message-setting',
        component: () => import('@/views/shortMessageSetting/ShortMessageSettingMain.vue'),
        name: 'short-message-setting',
        meta: {
          protected: false,
          title: 'router.short_message_setting_main'
        }
      },
      {
        path: '/notification',
        component: () => import('@/views/notification/NotificationMain.vue'),
        name: 'notification',
        meta: {
          protected: false,
          title: 'router.notification_main'
        }
      },
      {
        path: '/privacy-policy',
        component: () => import('@/views/privacyPolicy/PrivacyPolicyMain.vue'),
        name: 'privacy-policy',
        meta: {
          protected: false,
          title: 'router.privacy_policy_main'
        }
      },
      {
        path: '/information-collection-agreement',
        component: () => import('@/views/informationCollectionAgreement/InformationCollectionAgreementMain.vue'),
        name: 'information-collection-agreement',
        meta: {
          protected: false,
          title: 'router.information_collection_agreement_main'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    hidden: true,
    redirect: { path: '/404' }
  }
]

export { routes }
